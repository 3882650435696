import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Looking for a `}<Link to='/docs/materia/javascript-SDK/quick-start' mdxType="Link">{`quickstart`}</Link>{`?`}</p>
    <p>{`While the SDK is fully self-contained, there are two cases where it needs `}<em parentName="p">{`on-chain data`}</em>{` to function.
This guide will detail both of these cases, and offer some strategies that you can use to fetch this data.`}</p>
    <h1 {...{
      "id": "case-1-tokens",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#case-1-tokens",
        "aria-label": "case 1 tokens permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Case 1: Tokens`}</h1>
    <p>{`Unsurprisingly, the SDK needs some notion of an ERC-20 token to be able to function. This immediately raises the question of `}<em parentName="p">{`where data about tokens comes from`}</em>{`.`}</p>
    <p>{`As an example, let’s try to represent WUSD in a format the SDK can work with. To do so, we need at least 3 pieces of data: a `}<strong parentName="p">{`chainId`}</strong>{`, a `}<strong parentName="p">{`token address`}</strong>{`, and how many `}<strong parentName="p">{`decimals`}</strong>{` the token has. We also may be interested in the `}<strong parentName="p">{`symbol`}</strong>{` and/or `}<strong parentName="p">{`name`}</strong>{` of the token.`}</p>
    <h2 {...{
      "id": "identifying-data",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#identifying-data",
        "aria-label": "identifying data permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Identifying Data`}</h2>
    <p>{`The first two pieces of data — `}<strong parentName="p">{`chainId`}</strong>{` and `}<strong parentName="p">{`token address`}</strong>{` — must be provided by us. Thinking about it, this makes sense, as there’s really no other way to unambiguously identify a token.`}</p>
    <p>{`So, in the case of WUSD, we know that the `}<strong parentName="p">{`chainId`}</strong>{` is `}<inlineCode parentName="p">{`1`}</inlineCode>{` (we’re on mainnet), and the `}<strong parentName="p">{`token address`}</strong>{` is `}<inlineCode parentName="p">{`0x7C974104DF9dd7fb91205ab3D66d15AFf1049DE8`}</inlineCode>{`. Note that it’s very important to externally verify token addresses. Don’t use addresses from sources you don’t trust!`}</p>
    <h2 {...{
      "id": "required-data",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#required-data",
        "aria-label": "required data permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Required Data`}</h2>
    <p>{`The next piece of data we need is `}<strong parentName="p">{`decimals`}</strong>{`.`}</p>
    <h3 {...{
      "id": "provided-by-the-user",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#provided-by-the-user",
        "aria-label": "provided by the user permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Provided by the User`}</h3>
    <p>{`One option here is to simply pass in the correct value, which we may know is `}<inlineCode parentName="p">{`18`}</inlineCode>{`. At this point, we’re ready to represent WUSD as a `}<Link to='/docs/materia/SDK/token' mdxType="Link">{`Token`}</Link>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import { ChainId, Token } from '@materia/sdk'

const chainId = ChainId.MAINNET
const tokenAddress = '0x7C974104DF9dd7fb91205ab3D66d15AFf1049DE8' // must be checksummed
const decimals = 18

const WUSD = new Token(chainId, tokenAddress, decimals)
`}</code></pre>
    <p>{`If we don’t know or don’t want to hardcode the value, we could look it up ourselves via any method of retrieving on-chain data in a function that looks something like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import { ChainId } from '@materia/sdk'

async function getDecimals(chainId: ChainId, tokenAddress: string): Promise<number> {
  // implementation details
}
`}</code></pre>
    <h3 {...{
      "id": "fetched-by-the-sdk",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#fetched-by-the-sdk",
        "aria-label": "fetched by the sdk permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Fetched by the SDK`}</h3>
    <p>{`If we don’t want to provide or look up the value ourselves, we can ask the SDK to look it up for us with `}<Link to='/docs/materia/SDK/fetcher#fetchtokendata' mdxType="Link">{`Fetcher.fetchTokenData`}</Link>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import { ChainId, Token, Fetcher } from '@materia/sdk'

const chainId = ChainId.MAINNET
const tokenAddress = '0x7C974104DF9dd7fb91205ab3D66d15AFf1049DE8' // must be checksummed

// note that you may want/need to handle this async code differently,
// for example if top-level await is not an option
const WUSD: Token = await Fetcher.fetchTokenData(chainId, tokenAddress)
`}</code></pre>
    <p>{`By default, this method will use the `}<a parentName="p" {...{
        "href": "https://docs.ethers.io/v5/api/providers/#providers-getDefaultProvider"
      }}>{`default provider defined by ethers.js`}</a>{`.
If you’re already using ethers.js in your application, you may pass in your provider as a 3rd argument.
If you’re using another library, you’ll have to fetch the data separately.`}</p>
    <h2 {...{
      "id": "optional-data",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#optional-data",
        "aria-label": "optional data permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Optional Data`}</h2>
    <p>{`Finally, we can talk about `}<strong parentName="p">{`symbol`}</strong>{` and `}<strong parentName="p">{`name`}</strong>{`. Because these fields aren’t used anywhere in the SDK itself, they’re optional, and can be provided if you want to use them in your application. However, the SDK will not fetch them for you, so you’ll have to provide them:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import { ChainId, Token } from '@materia/sdk'

const WUSD = new Token(
  ChainId.MAINNET,
  '0x7C974104DF9dd7fb91205ab3D66d15AFf1049DE8',
  18,
  'WUSD',
  'Wrapped USD'
)
`}</code></pre>
    <p>{`or:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import { ChainId, Token, Fetcher } from '@materia/sdk'

// note that you may want/need to handle this async code differently,
// for example if top-level await is not an option
const WUSD = await Fetcher.fetchTokenData(
  ChainId.MAINNET,
  '0x7C974104DF9dd7fb91205ab3D66d15AFf1049DE8',
  undefined,
  'WUSD',
  'Wrapped USD'
)
`}</code></pre>
    <h1 {...{
      "id": "case-2-pairs",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#case-2-pairs",
        "aria-label": "case 2 pairs permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Case 2: Pairs`}</h1>
    <p>{`Now that we’ve explored how to define a token, let’s talk about pairs. To read more about what Materia pairs are, see `}<Link to='/docs/materia/smart-contracts/pair' mdxType="Link">{`Pair`}</Link>{`.`}</p>
    <p>{`As an example, let’s try to represent the WUSD-IETH pair.`}</p>
    <h2 {...{
      "id": "identifying-data-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#identifying-data-1",
        "aria-label": "identifying data 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Identifying Data`}</h2>
    <p>{`Each pair consists of two tokens (see previous section). Note that IETH used by the orchestrator is `}<Link to='/docs/materia/SDK/other-exports/#weth' mdxType="Link">{`exported by the SDK`}</Link>{`.`}</p>
    <h2 {...{
      "id": "required-data-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#required-data-1",
        "aria-label": "required data 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Required Data`}</h2>
    <p>{`The data we need is the `}<em parentName="p">{`reserves`}</em>{` of the pair. To read more about reserves, see `}<Link to='/docs/materia/smart-contracts/pair#getreserves' mdxType="Link">{`getReserves`}</Link>{`.`}</p>
    <h3 {...{
      "id": "provided-by-the-user-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#provided-by-the-user-1",
        "aria-label": "provided by the user 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Provided by the User`}</h3>
    <p>{`One option here is to simply pass in values which we’ve fetched ourselves to create a `}<Link to='/docs/materia/SDK/pair' mdxType="Link">{`Pair`}</Link>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import { ChainId, Token, IETH, Pair, TokenAmount } from '@materia/sdk'

const WUSD = new Token(ChainId.MAINNET, '0x7C974104DF9dd7fb91205ab3D66d15AFf1049DE8', 18)

async function getPair(): Promise<Pair> {
  const pairAddress = Pair.getAddress(WUSD, IETH[WUSD.chainId])

  const reserves = [/* use pairAddress to fetch reserves here */]
  const [reserve0, reserve1] = reserves

  const tokens = [WUSD, IETH[WUSD.chainId]]
  const [token0, token1] = tokens[0].sortsBefore(tokens[1]) ? tokens : [tokens[1], tokens[0]]

  const pair = new Pair(new TokenAmount(token0, reserve0), new TokenAmount(token1, reserve1))
  return pair
}
`}</code></pre>
    <p>{`Note that these values can change as frequently as every block, and should be kept up-to-date.`}</p>
    <h3 {...{
      "id": "fetched-by-the-sdk-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#fetched-by-the-sdk-1",
        "aria-label": "fetched by the sdk 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Fetched by the SDK`}</h3>
    <p>{`If we don’t want to look up the values ourselves, we can ask the SDK to look them up for us with `}<Link to='/docs/materia/SDK/fetcher#fetchpairdata' mdxType="Link">{`Fetcher.fetchPairData`}</Link>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import { ChainId, Token, IETH, Fetcher } from '@materia/sdk'

const WUSD = new Token(ChainId.MAINNET, '0x7C974104DF9dd7fb91205ab3D66d15AFf1049DE8', 18)

// note that you may want/need to handle this async code differently,
// for example if top-level await is not an option
const pair = await Fetcher.fetchPairData(WUSD, IETH[WUSD.chainId])
`}</code></pre>
    <p>{`By default, this method will use the `}<a parentName="p" {...{
        "href": "https://docs.ethers.io/v5/api/providers/#providers-getDefaultProvider"
      }}>{`default provider defined by ethers.js`}</a>{`. If you’re already using ethers.js in your application, you may pass in your provider as a 3rd argument. If you’re using another library, you’ll have to fetch the data separately.`}</p>
    <p>{`Note that these values can change as frequently as every block, and should be kept up-to-date.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      